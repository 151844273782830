<template>
    <AModalContent class="formulas-prize-modal" :class="type">
        <Transition name="t-formulas-prize-modal">
            <div class="formulas-prize-modal-animation" v-if="state === 1">
                <video
                    v-if="isVideoLoaded"
                    :src="bufferedVideoUrl"
                    autoplay
                    class="formulas-prize-modal__video"
                    preload="auto"
                    @ended="onVideoEnded"
                    @click.prevent="state = 2"
                />
                <img src="@/assets/img/spinner-clear.svg" class="loader" width="100" v-else>
            </div>
            <div class="formulas-prize-modal-content" v-else>
                <FormulasPrize @close="$emit('close')" :type="type" :item="item"  />
            </div>
        </Transition>
    </AModalContent>
</template>
<script setup>
import { ref } from "vue-demi";
import AModalContent from "../Modal/AModalContent.vue";
import FormulasPrize from "../Formulas/FormulasPrize/FormulasPrize.vue";
// forge_block / trait
const props = defineProps({ type: String, item: Object })
const videoLinkForgeBlock = require('@/assets/video/forge_burn.mp4')
const videoLinkForgeTrait = require('@/assets/video/forge_trait.mp4')

const videoLink = props.type === 'forge_block' ? videoLinkForgeBlock : videoLinkForgeTrait
const state = ref(1)
const isVideoLoaded = ref(false)
const bufferedVideoUrl = ref('')
async function preloadVideo() {
    const res = await fetch(videoLink);
    const blob = await res.blob();
    isVideoLoaded.value = true
    bufferedVideoUrl.value = URL.createObjectURL(blob);
}

preloadVideo()
const onVideoEnded = () => {
    state.value = 2
}
</script>
<style lang="scss">
.formulas-prize-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: none;
    &-animation {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*background-color: #121217;*/
        background-color: #000;

        display: flex;
        align-items: center;
        justify-content: center;
        video {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: auto;
        }
    }
    &-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: bold;
    }
    &.trait &{
        &-animation {
            background-color: #121217;
        }
    }
}

.t-formulas-prize-modal-leave-active {
    transition: 0.5s;
}
.t-formulas-prize-modal-enter-active {
    transition: 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.t-formulas-prize-modal-enter-from {
    opacity: 0;
    transform: scale(0.2);
}

.t-formulas-prize-modal-leave-to {
    opacity: 0;
}
</style>